import React, { useState, useEffect, useContext, useCallback } from "react";

import styled, { keyframes } from "styled-components";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Link } from "gatsby";
import { graphql } from 'gatsby'
import cities from "../cities.json";

import map from "../assets/images/map/map.svg";
import zaporozhyeMap from "../assets/images/map/zaporozhye.svg";
import kremenchukMap from "../assets/images/map/kremenchuk.svg";
import poltavaMap from "../assets/images/map/poltava.svg";
import mariupolMap from "../assets/images/map/mariupol.svg";
import kharkivMap from "../assets/images/map/kharkiv.svg";
import cherkasyMap from "../assets/images/map/cherkasy.svg";
import khersonMap from "../assets/images/map/kherson.svg";
import nikolayevMap from "../assets/images/map/nikolayev.svg";
import odessaMap from "../assets/images/map/odessa.svg";
import lvivMap from "../assets/images/map/lviv.svg";
import Seo from "../components/seo";
import {I18nextContext, Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import { LocaleContext } from "../../locale";

const Pointer = ({ isActive, city }) => {
  let Component;

  switch (city) {
    case "poltava":
      Component = PointerPoltavaStyled;
      break;
    case "cherkasy":
      Component = PointerCherkasyStyled;
      break;
    case "kharkiv":
      Component = PointerKharkivStyled;
      break;
    case "zaporozhye":
      Component = PointerZaporozhyeStyled;
      break;
    case "kremenchuk":
      Component = PointerKremenchugStyled;
      break;
    case "mariupol":
      Component = PointerMariupolStyled;
      break;
    case "kherson":
      Component = PointerKhersonStyled;
      break;
    case "mykolaiv":
      Component = PointerNikolayevStyled;
      break;
    case "odessa":
      Component = PointerOdessaStyled;
      break;
    case "lviv":
      Component = PointerLvivStyled;
      break;
    default:
      break;
  }

  if (!Component) {
    return null;
  }

  return <Component isHovered={isActive} />;
};

const Region = ({ isActive, city }) => {
  let src;

  switch (city) {
    case "poltava":
      src = poltavaMap;
      break;
    case "cherkasy":
      src = cherkasyMap;
      break;
    case "kharkiv":
      src = kharkivMap;
      break;
    case "zaporozhye":
      src = zaporozhyeMap;
      break;
    case "kremenchuk":
      src = kremenchukMap;
      break;
    case "mariupol":
      src = mariupolMap;
      break;
    case "kherson":
      src = khersonMap;
      break;
    case "mykolaiv":
      src = nikolayevMap;
      break;
    case "odessa":
      src = odessaMap;
      break;
    case "lviv":
      src = lvivMap;
      break;
    default:
      break;
  }

  if (!src) {
    return null;
  }

  return <RegionStyled src={src} alt="Region" isHovered={isActive} />;
};

const IndexPage = () => {
  const breakpoints = useBreakpoint();
  const [selectedCity, setselectedCity] = useState(null);
  const { t, i18n } = useTranslation();
  const context = React.useContext(I18nextContext);
  const { locale } = useContext(LocaleContext);

  const cityName = useCallback(()=>{
    return (
      <>
      <CityListStyled>
        {cities
          .sort((a, b) => (a.name.ua < b.name.ua ? -1 : 1))
          .map((city) => (
            <li
              onMouseOver={() => setselectedCity(city.slug)}
              onFocus={() => setselectedCity(city.slug)}
              onMouseLeave={() => setselectedCity(null)}
              key={city.slug}
              role="presentation"
            >
              <ListItemLinkStyled to={
                context.path === context.originalPath ? "/" + city.slug : "/" + context.path + city.slug
                }>
                <ListItemCheckboxStyled />
                {city.name[i18n.language]}
              </ListItemLinkStyled>
            </li>
          ))}
      </CityListStyled>
      </>
    )
  }, [context.language])

  return (
    <ContainerStyled>
      <TitleStyled>{t('chooseCity')}</TitleStyled>
      {cityName()}
      {breakpoints.tablet ? null : (
        <MapStyled>
          <MapIconStyled src={map} alt="Map" />
          <div>
            {cities.map((city) => (
              <Pointer
                city={city.slug}
                key={city.slug}
                isActive={selectedCity === city.slug}
              />
            ))}
          </div>
          <div>
            {cities.map((city) => (
              <Region
                city={city.slug}
                key={city.slug}
                isActive={selectedCity === city.slug}
              />
            ))}
          </div>
        </MapStyled>
      )}
    </ContainerStyled>
  );
};

export const Head = ({ pageContext }) => (
  <>
    <Seo 
      context={pageContext}
      title="Matrix VR - Клуб віртуальної реальності" 
      description="Клуб MATRIX пропонує своїм клієнтам тільки ті ігри, що відрізняються чудовою графікою та чітким зображенням. Це є запорукою максимального занурення та ваших позитивних емоцій." 
      />
  </>
)

export default IndexPage;

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;

  height: 100vh;
  padding-bottom: 75px;

  overflow: auto;
`;

const TitleStyled = styled.p`
  margin-top: 8vh;

  font-size: 3rem;
  text-align: center;
  font-weight: 600;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 2rem;
  }
  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 1.7rem;
  }
`;

const CityListStyled = styled.ul`
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 15px;
  grid-column-gap: 40px;
  max-width: 600px;
  margin: 30px auto;
  padding: 0;

  list-style: none;
  z-index: 1;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const ListItemLinkStyled = styled(Link)`
  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.white};
  font-size: 1.5rem;

  &:hover {
    color: ${(props) => props.theme.colors.green};
    span {
      background: ${(props) => props.theme.colors.white};
      border: 9px solid ${(props) => props.theme.colors.green};
    }
  }
`;
const ListItemCheckboxStyled = styled.span`
  width: 25px;
  height: 25px;
  margin-right: 20px;

  position: relative;
  top: 3px;

  border-radius: 50%;
  background: transparent;
  border: 2px solid ${(props) => props.theme.colors.white};
  transition: 0.3s ease;
`;

const MapStyled = styled.div`
  display: block;

  width: 560px;
  height: 375px;
  margin: 5vh auto 0;

  position: relative;
`;
const MapIconStyled = styled.img`
  width: 100%;
  height: 100%;
`;

const RegionStyled = styled.img`
  display: ${({ isHovered }) => (isHovered ? "block" : "none")};

  height: 100%;
  width: 100%;

  position: absolute;
  z-index: 12;
  top: 0px;
`;

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
`;

const PointerStyled = styled.span`
  display: block;

  width: 12px;
  height: 12px;
  transform: scale(1);

  position: absolute;
  z-index: 13;

  border-radius: 50%;
  background: ${(props) =>
    props.isHovered ? props.theme.colors.white : props.theme.colors.green};
  box-shadow: ${({ isHovered }) =>
    isHovered ? "0 0 4px 2px rgba(255,255,255,0.7)" : "unset!important"};
  animation: ${pulse} 1000ms infinite;
`;
const PointerPoltavaStyled = styled(PointerStyled)`
  top: 121px;
  right: 179px;
`;
const PointerCherkasyStyled = styled(PointerStyled)`
  top: 135px;
  right: 258px;
`;
const PointerKharkivStyled = styled(PointerStyled)`
  top: 108px;
  left: 425px;
`;
const PointerZaporozhyeStyled = styled(PointerStyled)`
  top: 216px;
  left: 405px;
`;
const PointerKremenchugStyled = styled(PointerStyled)`
  top: 139px;
  left: 339px;
`;
const PointerMariupolStyled = styled(PointerStyled)`
  top: 240px;
  right: 75px;
`;
const PointerKhersonStyled = styled(PointerStyled)`
  top: 278px;
  right: 225px;
`;
const PointerNikolayevStyled = styled(PointerStyled)`
  top: 245px;
  right: 242px;
`;
const PointerOdessaStyled = styled(PointerStyled)`
  top: 272px;
  left: 252px;
`;
const PointerLvivStyled = styled(PointerStyled)`
  top: 117px;
  left: 55px;
`;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;